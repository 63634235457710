<script>
import IconColorMixin from '@/mixins/IconColorMixin';

export default defineNuxtComponent({
  mixins: [IconColorMixin],
  props: {
    iconName: {
      type: String,
      default: '',
    },
    size: {
      type: [Number, String],
      default: 24,
    },
    accent: {
      type: String,
      default: 'currentColor',
    },
    darkMode: {
      type: Boolean,
      default: false,
    },
  },
});
</script>

<template>
  <svg
    :width="size"
    :height="size"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M7 20H17"
      :stroke="accent"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M14.3442 13.272H9.6626L8.68311 16.0859H6.5L11.0654 4H12.9497L17.5234 16.0859H15.332L14.3442 13.272ZM10.252 11.5786H13.7549L12.0034 6.56494L10.252 11.5786Z"
      :fill="getBaseColor(darkMode)"
    />
  </svg>
</template>
